<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link class="active" :to="{ name: 'ListArticle' }"
                    >Danh sách article</router-link
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Article name</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row" style="min-height: 52px">
                <div class="col-12 text-right">
                  <base-button
                    size="md"
                    type="primary"
                    @click="showImportArticleModal = true"
                    ><i class="ni ni-fat-add"></i> Import Article</base-button
                  >
                </div>
              </div>
              <html-editor v-model="input"></html-editor>
              <div
                v-if="errors.get('input')"
                class="invalid-feedback mb-4"
                :style="{
                  display: errors.get('input') ? 'block' : 'none',
                }"
              >
                {{ errors.get("input") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="row" style="min-height: 52px">
                <div class="col-12">
                  <h3 v-if="uniqueness_percent">
                    Uniqueness Score: {{ uniqueness_percent + "%" }}
                  </h3>
                </div>
              </div>
              <template v-if="rewrites.length > 0">
                <div v-for="(rewrite, index) in rewrites" :key="index">
                  <html-editor
                    v-if="index + 1 == pagination.default"
                    :value="rewrite"
                  ></html-editor>
                </div>

                <base-pagination
                  :page-count="rewrites.length"
                  :total="rewrites.length"
                  v-model="pagination.default"
                ></base-pagination>
              </template>
              <template v-else>
                <html-editor></html-editor>
              </template>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Number of rewrites:">
                <el-select v-model="rewrite_num">
                  <el-option
                    v-for="num in 10"
                    :key="num"
                    :label="num"
                    :value="num"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Number of rewrites:">
                <el-select v-model="uniqueness">
                  <el-option
                    v-for="uniqueness in uniquenessOptions"
                    :key="uniqueness.value"
                    :label="uniqueness.label"
                    :value="uniqueness.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="text-center mt-4">
            <base-button
              type="primary"
              native-type="submit"
              @click.prevent="rewriteArticle"
              :loading="isLoading"
              >Rewrite Article</base-button
            >

            <base-button
              v-if="rewrites.length > 0"
              type="success"
              native-type="button"
              @click.prevent="showSaveRewriteArticleModal = true"
              ><i class="fas fa-save"></i>Save Article</base-button
            >
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showImportArticleModal" size="lg" id="add-keyword-modal">
      <div>
        <!-- Form groups used in grid -->
        <ListImportArticle v-on:selectArticle="selectArticle" />
      </div>
    </modal>

    <modal
      :show.sync="showSaveRewriteArticleModal"
      size="lg"
      id="save-rewrite-article-modal"
    >
      <div class="container-fluid">
        <!-- Form groups used in grid -->
        <div class="row">
          <div class="col-md-12">
            <base-input
              v-model="article_name"
              :inputClasses="errors.get('name') ? 'is-invalid' : ''"
              label="Article Name"
              :errors="errors.get('name') ? [errors.get('name')] : []"
              maxlength="255"
            >
            </base-input>
          </div>
        </div>

        <base-button
          type="primary"
          native-type="submit"
          @click.prevent="saveRewriteArticle"
          :loading="isSaveButtonLoading"
          >Save Article</base-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
import ListImportArticle from "@/views/Pages/Articles/ListImportArticle";
import Errors from "@/utils/error";
import _ from "lodash";

export default {
  name: "RewriteArticle",
  components: {
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    ListImportArticle,
  },
  data() {
    return {
      pagination: {
        default: 1,
      },
      article: {},
      article_name: "",
      input: "",
      showRewriteIndex: 0,
      rewrite_num: 1,
      rewrites: [],
      text: "",
      uniqueness: 1,
      uniqueness_percent: 0,
      uniquenessOptions: [
        { label: "More Conservative", value: 1 },
        { label: "Regular", value: 2 },
        { label: "More Adventurous", value: 3 },
      ],
      isLoading: false,
      isSaveButtonLoading: false,
      showImportArticleModal: false,
      showSaveRewriteArticleModal: false,
      errors: new Errors(),
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getRewriteArticle();
    }
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    },
  },
  methods: {
    async getRewriteArticle() {
      this.$preloaders.open();

      try {
        const data = await this.$store.dispatch(
          "articles/getRewriteArticle",
          this.$route.params.id
        );
        this.article = data.result;
        this.rewrites = data.result.rewrites;
        this.text = data.result.text;
        this.input = data.result.input;
        this.article_name = data.result.name;
        this.uniqueness_percent = data.result.uniqueness;
      } catch (err) {
        console.log(err);
      }

      this.$preloaders.close();
    },
    selectArticle(row) {
      this.input = row.spin;
      this.showImportArticleModal = false;
    },
    rewriteArticle() {
      let self = this;
      this.isLoading = true;
      this.errors = new Errors();

      this.$store
        .dispatch("articles/rewriteArticle", {
          input: this.input,
          uniqueness: this.uniqueness,
          rewrite_num: this.rewrite_num,
        })
        .then((response) => {
          self.isLoading = false;
          self.rewrites = response.result.rewrites;
          self.text = response.result.text;
          self.uniqueness_percent = response.result.uniqueness;
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText =
              "Rewrite article không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          this.$notify({
            message: self.errText,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
          });
          self.isLoading = false;
        });
    },
    saveRewriteArticle() {
      let self = this;
      this.isSaveButtonLoading = true;
      this.errors = new Errors();

      this.$store
        .dispatch("articles/saveRewriteArticle", {
          input: this.input,
          uniqueness: this.uniqueness_percent,
          rewrites: this.rewrites,
          text: this.text,
          name: this.article_name,
          rewrite_num: this.rewrite_num,
        })
        .then((response) => {
          self.isSaveButtonLoading = false;
          self.showSaveRewriteArticleModal = false;
          let rewriteArticleId = response.result._id;

          if (rewriteArticleId) {
            self.$router.push({
              path: "rewrite/" + rewriteArticleId,
            });
          }

          this.$notify({
            message: response.message,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
          });
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText = "Lưu article không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          this.$notify({
            message: self.errText,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
          });
          self.isSaveButtonLoading = false;
        });
    },
  },
};
</script>

<style>
.ql-toolbar + .ql-container .ql-editor {
  max-height: 500px;
  height: 500px;
  overflow: auto;
}
</style>
