<template>
  <div>
    <div class="">
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="listQuery.limit"
            placeholder="Per page"
            @change="handleFilter"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <base-input
              v-model="listQuery.search"
              prepend-icon="fas fa-search"
              placeholder="Tìm kiếm..."
              @keyup.enter.native="handleFilter"
            >
            </base-input>
          </div>
        </div>

        <el-table
          :data="tableData"
          row-key="id"
          header-row-class-name="thead-light"
          v-loading="listLoading"
          @sort-change="sortChange"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-bind="{
              label: 'Tên',
              minWidth: 220,
              sortable: true,
            }"
          >
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'ArticleDetail',
                  params: { id: scope.row._id },
                }"
                >{{ scope.row.name }}</router-link
              >
            </template>
          </el-table-column>

          <el-table-column
            v-bind="{
              label: 'Từ khóa',
              minWidth: 220,
              sortable: false,
            }"
          >
            <template slot-scope="scope">
              <badge rounded type="success">{{ scope.row.keyword }}</badge
              >&nbsp;
              <badge
                rounded
                type="info"
                v-for="keyword in scope.row.sub_keywords"
                :key="keyword"
                >{{ keyword }}</badge
              >&nbsp;
            </template>
          </el-table-column>

          <el-table-column
            v-bind="{
              label: 'Cập nhật',
              minWidth: 160,
              sortable: true,
            }"
          >
            <template slot-scope="scope">
              {{ scope.row.updated_at | formatToLocaleDatetime }}
            </template>
          </el-table-column>

          <el-table-column min-width="120px" align="right" label="">
            <div slot-scope="{ row }" class="d-flex">
              <base-button
                @click="$emit('selectArticle', row)"
                type="primary"
                size="sm"
                icon
              >
                Chọn
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">Tổng cộng: {{ pagination.total }}</p>
        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :page-count="pagination.pageCount"
          :total="pagination.total"
          @input="getList"
        >
        </base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import { formatToLocaleDatetime } from "@/utils";

export default {
  name: "ListImportArticle",
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      tableData: [],
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0,
        pageCount: 1,
      },
      listQuery: {
        page: 1,
        limit: 100,
        search: undefined,
        sortBy: "created_at",
      },
      listLoading: false,
      urls: [],
    };
  },
  filters: {
    formatToLocaleDatetime(dateTime) {
      return formatToLocaleDatetime(dateTime);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(page) {
      // To prevent duplicate API call when input event emit from base pagination component
      if (page == this.listQuery.page) return;

      this.listLoading = true;
      this.listQuery.page = this.pagination.currentPage;
      const { result } = await this.$store.dispatch(
        "articles/fetchArticles",
        this.listQuery
      );

      this.tableData = result.data;
      this.pagination.total = result.meta.total;
      this.pagination.currentPage = result.meta.current_page;
      this.pagination.perPage = parseInt(result.meta.per_page);
      this.pagination.pageCount = result.meta.last_page;
      this.listQuery.page = result.meta.current_page;
      this.listLoading = false;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
