var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{},[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},on:{"change":_vm.handleFilter},model:{value:(_vm.listQuery.limit),callback:function ($$v) {_vm.$set(_vm.listQuery, "limit", $$v)},expression:"listQuery.limit"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Tìm kiếm..."},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleFilter.apply(null, arguments)}},model:{value:(_vm.listQuery.search),callback:function ($$v) {_vm.$set(_vm.listQuery, "search", $$v)},expression:"listQuery.search"}})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"data":_vm.tableData,"row-key":"id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange,"selection-change":_vm.selectionChange}},[_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'ArticleDetail',
                params: { id: scope.row._id },
              }}},[_vm._v(_vm._s(scope.row.name))])]}}])},'el-table-column',{
            label: 'Tên',
            minWidth: 220,
            sortable: true,
          },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('badge',{attrs:{"rounded":"","type":"success"}},[_vm._v(_vm._s(scope.row.keyword))]),_vm._v("  "),_vm._l((scope.row.sub_keywords),function(keyword){return _c('badge',{key:keyword,attrs:{"rounded":"","type":"info"}},[_vm._v(_vm._s(keyword))])}),_vm._v("  ")]}}])},'el-table-column',{
            label: 'Từ khóa',
            minWidth: 220,
            sortable: false,
          },false)),_c('el-table-column',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatToLocaleDatetime")(scope.row.updated_at))+" ")]}}])},'el-table-column',{
            label: 'Cập nhật',
            minWidth: 160,
            sortable: true,
          },false)),_c('el-table-column',{attrs:{"min-width":"120px","align":"right","label":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"d-flex"},[_c('base-button',{attrs:{"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.$emit('selectArticle', row)}}},[_vm._v(" Chọn ")])],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v("Tổng cộng: "+_vm._s(_vm.pagination.total))])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"page-count":_vm.pagination.pageCount,"total":_vm.pagination.total},on:{"input":_vm.getList},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }